<template>
  <v-dialog
    v-if="show"
    :value="showDialog"
    :max-width="maxWidth"
    scrollable
    :content-class="customClass"
    @input="closeModal"
    @click:outside="closeModal"
    @keydown.enter="confirmModal"
    @keyup.esc="closeModal"
  >
    <v-card>
      <v-card-title class="base-dialog__title justify-space-between">
        <v-btn
          icon
          :class="titleDirection[0]"
          @click="closeModal"
          @keyup.esc="fecharModal"
        >
          <v-icon>$close</v-icon>
        </v-btn>

        <span :class="titleDirection[1]">{{ title }}</span>
      </v-card-title>

      <v-card-text
        v-if="hasFooterSlot"
        class="base-dialog__content pb-0"
        :style="maxHeight"
      >
        <slot name="content" />
      </v-card-text>

      <v-card-actions
        v-if="showFooterActions"
        :class="['pb-3', buttonDirection]"
      >
        <v-btn
          text
          outlined
          @click="closeModal"
        >
          {{ cancelButtonText }}
        </v-btn>
        <v-btn color="primary" @click="confirmModal">
          {{ confirmButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const validators = {
  show: [true, false],
  position: ['center', 'left', 'right'],
  closeDirection: ['left', 'right'],
  buttonsDirection: ['center', 'left', 'right'],
};
const defaultValues = {
  title: '',
  width: 600,
  height: 430,
  position: 'center',
  show: false,
  closeDirection: 'right',
  cancelButtonText: 'cancel',
  confirmButtonText: 'confirm',
  buttonsDirection: 'right',
};

export default {
  name: 'BaseModal',
  components: {},
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: defaultValues.show,
      validator: (value) => {
        return validators.show.includes(value);
      },
    },
    position: {
      type: String,
      default: defaultValues.position,
      validator: (value) => {
        return validators.position.includes(value);
      },
    },
    title: {
      type: String,
      default: defaultValues.title,
    },
    width: {
      type: Number,
      default: defaultValues.width,
    },
    height: {
      type: Number,
      default: defaultValues.height,
    },
    closeDirection: {
      type: String,
      default: defaultValues.closeDirection,
      validator: (value) => {
        return validators.closeDirection.includes(value);
      },
    },
    showFooterActions: {
      type: Boolean,
      default: true,
    },
    cancelButtonText: {
      type: String,
      default: defaultValues.cancelButtonText,
    },
    confirmButtonText: {
      type: String,
      default: defaultValues.confirmButtonText,
    },
    buttonsDirection: {
      type: String,
      default: defaultValues.buttonsDirection,
      validator: (value) => {
        return validators.buttonsDirection.includes(value);
      },
    },
  },
  data() {
    return {
      hasFooterSlot: false,
    };
  },
  computed: {
    showDialog() {
      return this.show ? this.show : defaultValues.show;
    },
    positionClass() {
      const newPosition = this.position ? this.position : 'center';

      return `v-dialog-${newPosition}-position`;
    },
    customClass() {
      return `base-dialog ${this.positionClass}`;
    },
    maxWidth() {
      if (!this.width) return defaultValues.width;
      return this.width >= 400 ? this.width : defaultValues.width;
    },
    maxHeight() {
      const newHeight = this.height ? this.height : defaultValues.height;

      return {
        maxHeight: `${newHeight}px`,
        minHeight: '42px',
      };
    },
    titleDirection() {
      const defaultTitleDirection = ['order-0', 'order-1'];
      const currentCloseDirection = this.closeDirection
        ? this.closeDirection
        : defaultValues.closeDirection;

      return currentCloseDirection === 'right'
        ? defaultTitleDirection
        : defaultTitleDirection.reverse();
    },
    buttonDirection() {
      const directionClass =
        this.buttonsDirection &&
        validators.buttonsDirection.includes(this.buttonsDirection)
          ? this.buttonsDirection
          : defaultValues.buttonsDirection;
      let directionConverted = '';
      switch (directionClass) {
        case 'left':
          directionConverted = 'start';
          break;
        case 'right':
          directionConverted = 'end';
          break;
        default:
          directionConverted = 'center';
          break;
      }

      return `justify-${directionConverted}`;
    },
  },
  mounted() {
    this.checkContentSlot();
  },
  updated() {
    this.checkContentSlot();
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    confirmModal() {
      this.$emit('confirm-modal');
    },
    checkContentSlot() {
      this.hasFooterSlot = !!this.$slots.content;
    },
  },
};
</script>

<style lang="scss" scoped></style>
